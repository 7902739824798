import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TicketListComponent} from '@ticket/ticket-list/ticket-list.component';
import {ExtendedModule, FlexModule} from '@ngbracket/ngx-layout';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {TranslateModule} from '@ngx-translate/core';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {HeaderModule} from '@core/components/header/header.module';
import {SpinnerModule} from '@libs/ui/src/components/spinner/spinner.module';
import {RouterModule} from '@angular/router';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {CoreModule} from '@core/core.module';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {TicketStateDropdownModule} from '@ticket/ticket-state-dropdown/ticket-state-dropdown.module';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {BookmarkButtonModule} from '@libs/ui/src/components/bookmark-button/bookmark-button.module';
import {WorkLogModule} from '@ticket/work-log/work-log.module';
import {NumberRangeKeyModule} from '@libs/ui/src/components/number-range-key/number-range-key.module';
import {MissingEntriesInfoModule} from '@core/components/missing-entries-info/missing-entries-info.module';
import {FavoriteButtonComponent} from '@core/components/favorites/favorite-button.component';


@NgModule({
    declarations: [TicketListComponent],
    imports: [
        CommonModule,
        FlexModule,
        MatButtonModule,
        MatTooltipModule,
        MatChipsModule,
        TranslateModule,
        SearchInputModule,
        HeaderModule,
        SpinnerModule,
        RouterModule,
        MatTableModule,
        CoreModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        TicketStateDropdownModule,
        ExtendedModule,
        AvatarModule,
        BookmarkButtonModule,
        WorkLogModule,
        NumberRangeKeyModule,
        MissingEntriesInfoModule,
        FavoriteButtonComponent
    ],
    exports: [
        TicketListComponent
    ]
})
export class TicketListModule {
}
