import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WorklogComponentData} from '@ticket/work-log/worklog/worklog-dialog.data.model';
import {DialogService} from '@core/services/global/dialog.service';

@Component({
    selector: 'omt-worklog-button',
    templateUrl: './worklog-button.component.html',
    styleUrls: ['./worklog-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklogButtonComponent {
    @Input() ticketId: number;

    constructor(private readonly dialogService: DialogService) {
    }

    openWorklogDialog(): void {
        const data = new WorklogComponentData();
        data.ticketId = this.ticketId;

        this.dialogService.openWorklogDialog(data);
    }
}
