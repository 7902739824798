<div class="evaluation-host" fxLayout="column" fxLayoutGap="1rem">
  @if (isLoading) {
    <omt-spinner></omt-spinner>
  }
  <div fxLayout="row" fxLayoutAlign="end">
    <span>{{ 'GLOBAL.TOTAL' | translate }}: {{ totalSum | timeFormatFromMs }}</span>
  </div>

  @for (log of typeEvaluations; track log) {
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2rem">
      <span fxFlex="25" class="work-log-type">
        <i class="fas fa-file-signature"></i>
        {{ log.name }} ({{ log.percentage }}%)
      </span>
      <mat-progress-bar [value]="+log.percentage" class="rounded mat-elevation-z2" style="height: 12px;"></mat-progress-bar>
      <span class="work-log-ratio">{{ log.totalTime | timeFormatFromMs }}</span>
    </div>
  }
</div>
