import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorklogComponent} from '@ticket/work-log/worklog/worklog.component';
import {WorklogTableComponent} from '@ticket/work-log/worklog-table/worklog-table.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@core/core.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {FlexModule} from '@ngbracket/ngx-layout';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatIconModule} from '@angular/material/icon';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SharedModule} from '@shared/shared.module';
import {DatePickerModule} from '@core/components/datepicker/date-picker.module';
import {TimeSelectModule} from '@core/components/time-select/time-select.module';
import {WorkLogEvaluationComponent} from './work-log-evaluation/work-log-evaluation.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {SpinnerModule} from '@libs/ui/src/components/spinner/spinner.module';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {WorklogButtonComponent} from './worklog-button/worklog-button.component';
import {LocationIconModule} from '@core/components/location-icon/location-icon.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {WorklogTypeDropdownModule} from '@ticket/work-log/worklog-type-dropdown/worklog-type-dropdown.module';
import {BusinessUnitDropdownModule} from '@shared/components/dropdowns/business-unit-dropdown/business-unit-dropdown.module';


@NgModule({
    declarations: [WorklogComponent, WorklogTableComponent, WorkLogEvaluationComponent, WorklogButtonComponent],
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule,
        FormsModule,
        CoreModule,
        MatTooltipModule,
        FlexModule,
        NgSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatPaginatorModule,
        MatIconModule,
        DragDropModule,
        SharedModule,
        DatePickerModule,
        TimeSelectModule,
        MatListModule,
        SpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        MatDialogModule,
        DialogHostModule,
        LocationIconModule,
        MatCardModule,
        WorklogTypeDropdownModule,
        BusinessUnitDropdownModule
    ],
    exports: [WorklogComponent, WorklogTableComponent, WorklogButtonComponent]
})
export class WorkLogModule {
}
